import React from 'react'

function MyOverview() {
  return (
    <div>
      <h1 className="text-xl font-medium text-sky-700">
        My Overview
      </h1>
    </div>
  )
}

export default MyOverview